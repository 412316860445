import { usePage } from '@inertiajs/react'

export const optimizeImage = (url, width, quality = 80, format = 'auto') => {
    let settings

    const page = usePage()

    if (! url) {
        return url
    }

    if (typeof window === 'undefined') {
        // nodejs
        settings = {
            urls: {
                image_optimize: 'https://www.coolframes.com/cdn-cgi/image/'
            }
        }
    } else {
        settings = page.props.settings
    }

    const optimizeUrl = settings.urls.image_optimize

    if (optimizeUrl && url && url.indexOf('cdn-cgi/image') === -1) {
        // https://www.coolframes.com/cdn-cgi/image/width=199,quality=80/
        return optimizeUrl + `width=${width},quality=${quality},format=${format}/` + url
    }

    return url
}
