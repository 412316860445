import './bootstrap';
import './front';
import './storyblok';
import '../css/app.css';
import '../css/devcube.css';
import '../css/components/CartierAuthorizedDealerBanner.css';
import React from 'react'
import {createInertiaApp} from '@inertiajs/react';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import aa from 'search-insights';
import {ChatProvider} from '@/Components/ChatContext';
import { hydrateRoot } from 'react-dom/client';

const appName = import.meta.env.VITE_APP_NAME || 'Cool Frames';

Bugsnag.start({
    apiKey: '311cf6a0415b23c1182c1754a941ac1e',
    plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: '311cf6a0415b23c1182c1754a941ac1e' })

createInertiaApp({
    title: (title) => {
        if (!title) {
            return appName
        }
        return `${title} - ${appName}`
    },
    resolve: (name) => {
        return resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx'));
    },
    setup: ({el, App, props}) => {
        //el.classList.add('h-full')
        const zohoWidget = props.initialPage.props.zohoWidget;

        const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

        hydrateRoot(el,
            <ErrorBoundary>
                <ChatProvider zohoWidget={zohoWidget}>
                    <App {...props} />
                </ChatProvider>
            </ErrorBoundary>
        );
    }
}).then(() => {
    document.getElementById('app').removeAttribute('data-page')
});

// Initialize Algolia Insights
aa('init', {
    appId: import.meta.env.VITE_ALGOLIA_APP_ID,
    apiKey: import.meta.env.VITE_ALGOLIA_SEARCH_KEY,
    useCookie: true
});
