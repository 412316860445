import {apiPlugin, storyblokInit} from '@storyblok/react';

import Banner from '@/Components/Banner'
import BannerTop from '@/Components/BannerTop'
import ActivityBlock from '@/Components/Home/ActivityBlock'
import ActivityGrid from '@/Components/Home/ActivityGrid'
import CategoryBlock from '@/Components/CategoryBlock'
import CategoryGrid from '@/Components/CategoryGrid'
import CenterHero from '@/Components/CenterHero'
import CollectionSlider from '@/Components/Home/CollectionSlider'
import ContentPage from '@/Components/ContentPage'
import FAQ from '@/Components/FAQ'
import GlassesSlider from '@/Components/Home/GlassesSlider'
import HighlightedBrands from '@/Components/Home/HighlightedBrands'
import LinksGrid from '@/Components/LinksGrid'
import Tag from '@/Components/Home/Tag'
import TagList from '@/Components/Home/TagList'
import Welcome from '@/Components/Home/Welcome'
import WelcomeColumn from '@/Components/Home/WelcomeColumn'
import WelcomeBlock from '@/Components/Home/WelcomeBlock'
import Grid from '@/Components/Grid'
import Hero from '@/Components/Hero'
import Intro from '@/Components/Intro'
import StoreIntro from '@/Components/Store/Intro'
import StoreStaff from '@/Components/Store/Staff'
import Story from '@/Components/Story'
import StoryGrid from '@/Components/StoryGrid'
import FeaturedStory from '@/Components/FeaturedStory'

storyblokInit({
    accessToken: import.meta.env.VITE_STORYBLOK_TOKEN,
    cache: {
        clear: "manual",
        type: "memory"
    },
    use: [apiPlugin],
    components: {
        'activity-block': ActivityBlock,
        'activity-grid': ActivityGrid,
        'banner': Banner,
        'banner-top': BannerTop,
        'category-block': CategoryBlock,
        'category-grid': CategoryGrid,
        'center-hero': CenterHero,
        'collection-slider': CollectionSlider,
        'content-page': ContentPage,
        'glasses-slider': GlassesSlider,
        'highlighted-brands': HighlightedBrands,
        'tag': Tag,
        'tag-list': TagList,
        'welcome': Welcome,
        'welcome-column': WelcomeColumn,
        'welcome-block': WelcomeBlock,
        'grid': Grid,
        'hero': Hero,
        'intro': Intro,
        'story': Story,
        'story-grid': StoryGrid,
        'featured-story': FeaturedStory,
        'faq': FAQ,
        'links-grid': LinksGrid,
        'store-intro': StoreIntro,
        'store-staff': StoreStaff,
    },
    apiOptions: {
        region: "us"
    },
});
