import { usePage } from '@inertiajs/react';

const Hero = ({ blok }) => {
    const altText = blok.background?.alt || "";
    const invertColors = blok.invertColors || false;
    const { desktopImageUrl, mobileImageUrl } = usePage().props;

    return (
        <section>
            <div className="banner">
                <div className={"banner-bgc " + (invertColors ? 'no-overlay' : '')}>
                    <picture>
                        {mobileImageUrl && (
                            <source
                                media="(max-width: 767px)"
                                srcSet={mobileImageUrl}
                            />
                        )}
                        {desktopImageUrl && (
                            <source
                                media="(min-width: 768px)"
                                srcSet={desktopImageUrl}
                            />
                        )}
                        <img
                            loading="eager"
                            decoding="async"
                            src={desktopImageUrl || mobileImageUrl}
                            alt={altText}
                            width={desktopImageUrl ? 1912 : 430}
                            height={desktopImageUrl ? 646 : 330}
                            style={{width: '100%', height: 'auto'}}
                        />
                    </picture>
                </div>
                <div className={"banner-info " + (invertColors ? 'invert-colors' : '')}>
                    <h1 className="banner-info-title">{blok.title}</h1>
                    <p className="banner-info-text">{blok.description}</p>
                    <div className="banner-info-btns">
                        {blok.links.map((link) => (
                            <a key={link._uid} href={link.link.url} className="banner-info-btn">
                                {link.title}
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
