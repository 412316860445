import { storyblokEditable } from '@storyblok/react'
import { optimizeImage } from '@/Util/Images'

export default ({ blok }) => {
    return (
        <div className="shop-activity-item multiSlide" {...storyblokEditable(blok)}>
            <a className="shop-activity-item-img" href={blok.link.url}>
                <picture>
                    <source
                        media="(max-width: 767px)"
                        srcSet={optimizeImage(blok.image.filename, 290)}
                    />
                    <source
                        media="(min-width: 768px)"
                        srcSet={optimizeImage(blok.image.filename, 400)}
                    />
                    <img
                        src={optimizeImage(blok.image.filename, 400)}
                        alt={blok.image?.alt || ""}
                        loading="lazy"
                        decoding="async"
                        width="100%"
                        height="auto"
                    />
                </picture>
            </a>

            <a className="shop-activity-item-title" href={blok.link.url}>{blok.title}</a>

            <span className="shop-activity-item-text">
                {blok.description}
            </span>

            <a className="shop-activity-item-link" href={blok.link.url}>
                {blok.link_text}
            </a>
        </div>
    )
}
