import {optimizeImage} from "@/Util/Images.jsx";

export default ({ blok }) => {
		let className = '';
		if(blok.title == 'Virtual Try-On')
			className = 'auglio-tryon-btn';

    const ImageContent = () => (
        <picture>
            {blok.mobile_background?.filename && (
                <source
                    media="(max-width: 767px)"
                    srcSet={optimizeImage(blok.mobile_background.filename, 430)}
                />
            )}
            {blok.background?.filename && (
                <source
                    media="(min-width: 768px)"
                    srcSet={optimizeImage(blok.background.filename, 1750)}
                />
            )}
            <img
                loading="lazy"
                decoding="async"
                src={optimizeImage(blok.background.filename, 1750)}
                alt={blok.background?.alt || ""}
                width="100%"
                height="auto"
            />
        </picture>
    );

    return (
        <section className={"banner-block " + blok.class}>
	        <div className="section-full">
		        <div className="banner-block-cont">
			        {blok.class === 'cartier' && (
				        <h2 className="banner-block-title">
					        <img src="/img/Cartier.svg" alt=""/>
				        </h2>
			        )}
                    {blok.youtube ? (
                        <div className="banner-block-video">
                            <iframe src={blok.youtube}></iframe>
                        </div>
                    ) : (
                        blok.image_link ? (
                            <a href={blok.image_link.url} className="banner-block-img">
                                <ImageContent />
                            </a>
                        ) : (
                            <div className="banner-block-img">
                                <ImageContent />
                            </div>
                        )
                    )}
		        </div>
		        <div className="banner-block-info">
			        <h2 className="banner-block-info-title">{blok.title}</h2>
			        <p className="banner-block-info-text">{blok.description}</p>
			        <div className="banner-block-info-btns">
				        {blok.links.map((link) => (
					        <a key={link._uid} href={link.link.url}
					           className={className+" banner-block-info-btn"}>
						        {link.title}
					        </a>
				        ))}
			        </div>
		        </div>
	        </div>
        </section>
    )
}
