import {storyblokEditable} from '@storyblok/react'
import {optimizeImage} from "@/Util/Images.jsx";

const CategoryBlock = ({blok}) => {
	let classes = {
		li: "home-blog-item",
		imgWrapper: "home-blog-img",
		info: "home-blog-info",
		title: "home-blog-info-title",
		text: "home-blog-info-text",
		btns: "home-blog-info-btns",
		btn: "home-blog-info-btn"
	}

	if (blok.type === 'style') {
		classes = {
			li: "home-blog-item style-item",
			imgWrapper: "home-blog-img style-img",
			info: "home-blog-info style-info",
			title: "home-blog-info-title style-info-title",
			text: "home-blog-info-text style-info-text",
			btns: "home-blog-info-btns style-info-btns",
			btn: "style-info-btn"
		}
	}
    const getImage = () => {
        if (!blok.image?.filename) return null;

        return (
            <picture>
                {blok.mobile_image?.filename && (
                    <source
                        media="(max-width: 767px)"
                        srcSet={optimizeImage(blok.mobile_image.filename, 430)}
                    />
                )}
                <source
                    media="(min-width: 768px)"
                    srcSet={optimizeImage(blok.image.filename, 570)}
                />
                <img
                    loading="lazy"
                    decoding="async"
                    src={optimizeImage(blok.image.filename, 570)}
                    alt={blok.image.alt || ""}
                    width="100%"
                    height="auto"
                />
            </picture>
        )
    }

	return (
		<li className={classes.li} {...storyblokEditable(blok)}>
			{blok.image_link ? (
				<a href={blok.image_link.url} className={classes.imgWrapper}>
					{getImage()}
				</a>
			) : (
				<div className={classes.imgWrapper}>
					{getImage()}
				</div>
			)}
			<div className={classes.info}>
				{blok.subtitle && (
					<span className="home-blog-info-subtitle">{blok.subtitle}</span>
				)}
				<h2 className={classes.title}>{blok.title}</h2>
				<p className={classes.text}>
					{blok.description}
				</p>
				<div className={classes.btns}>
					{blok.links.map((link, i) => (
						<a key={i} href={link.link.url} className={classes.btn}>
							{link.title}
						</a>
					))}
				</div>
			</div>
		</li>
	);
};

export default CategoryBlock;
